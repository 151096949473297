<template>
  <div class="c-poster" :class="[link && image ? pClass : '']" v-if="link && image">
      <a :href="link" target="_blank">
          <img :src="image">
      </a>
  </div>
</template>

<script>
export default {
    name: 'c-poster-detail',
    props: {
        link: {
            type: String,
            default: '',
            required: true
        },
        image: {
            type: String,
            default: '',
            required: true
        },
        pClass: {
            type: String,
            default: ''
        },
    }
}
</script>

<style>
.c-poster{display: block;}
.c-poster img{display: block; width: 100%; height: 60px;}
</style>